import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';

const PrivacyAndPolicy = () => {
  const intl = useIntl();

  const isDeLang = () => intl.locale === 'de';

  return (
    <Layout
      section={'payment'}
      bannerTitle={intl.formatMessage({
        id: 'footer_privacy_policy',
        defaultMessage: 'Privacy and Policy',
      })}
    >
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns">
              <div className="column">
                {isDeLang() ? (
                  <div className="content px-6">
                    <p>
                      Diese Datenschutzerklärung gilt für die Online-Ticketplattform <a href="https://viennaeventtickets.com">www.viennaeventtickets.com</a>, die von Vienna Event Tickets, mit Sitz in Wien, Österreich, betrieben wird ("wir" oder "uns").
                      Wir verpflichten uns, die Privatsphäre und Sicherheit personenbezogener Informationen, die wir von Nutzern der Plattform ("Sie" oder "Nutzer") sammeln, zu schützen.</p>
                    <br />
                    <p>
                      <span><strong>1. Erhebung und Verwendung personenbezogener Informationen</strong></span><br /><br />
                      Wir können folgende Arten von personenbezogenen Informationen von Nutzern der Plattform sammeln:<br />
                      - Kontaktdaten: Hierzu gehören Ihr Name, Ihre E-Mail-Adresse, Ihre Telefonnummer und Ihre Postanschrift.
                      Wir erfassen diese Informationen, wenn Sie ein Konto auf der Plattform erstellen oder ein Ticket kaufen.<br />
                      - Zahlungsinformationen: Wir können Zahlungsinformationen, einschließlich Ihrer Kreditkarte oder anderen Zahlungsinformationen, erfassen, wenn Sie ein Ticket über die Plattform kaufen.<br />
                      - Nutzungsdaten: Wir können Informationen über Ihre Nutzung der Plattform, wie Ihren Browserverlauf, Suchverlauf und Ihre IP-Adresse, erfassen. <br />
                      Wir verwenden diese personenbezogenen Informationen, um:
                      - Ihre Ticketbestellungen zu bearbeiten und auszuführen;<br />
                      - auf Ihre Anfragen, Wünsche oder Stornierungen zu reagieren;<br />
                      - Kundenservice bereitzustellen; <br />
                      - die Funktionalität der Plattform zu analysieren und zu verbessern; <br />
                      - Betrug und andere illegale Aktivitäten zu verhindern; <br />
                      - gesetzlichen und regulatorischen Anforderungen zu entsprechen. <br />
                      Bitte beachten Sie, dass Sie durch die Eingabe Ihrer personenbezogenen Informationen auf unserer Plattform, die Benutzung Ihrer Daten unserseits für die oben angeführten Zwecke freiwillig einwilligen.
                      Die eingegebenen Daten werden sicher aufbewahrt und nicht an Dritte weitergegeben, es sei denn, wir sind gesetzlich dazu verpflichtet.
                    </p><br />
                    <p>
                      <span><strong>2. Cookies</strong></span> <br /><br />
                      Wir können Cookies und andere Tracking-Technologien verwenden, um Informationen über Ihre Nutzung der Plattform zu sammeln.
                      Cookies sind kleine Daten- oder Textdateien, die auf Ihrem Gerät gespeichert werden, wenn Sie eine Website besuchen.
                      Cookies bieten den Nutzern zusätzliche Funktionen, wie z. B. die Speicherung Ihrer Einstellungen und die Möglichkeit, die Website dort weiter zu nutzen, wo Sie sie verlassen haben.
                      Cookies können keine Daten auf Ihrem Computer lesen, darauf zugreifen oder sie verändern.
                      Wir verwenden Cookies, um:<br />
                      - Ihre Präferenzen und Einstellungen zu speichern; <br />
                      - Ihre Erfahrung auf der Plattform zu personalisieren;<br />
                      - die Funktionalität der Plattform zu analysieren und zu verbessern;<br />
                      - zielgerichtete Werbung zu schalten.<br />
                      Sie können die Verwendung von Cookies auf individueller Browser-Ebene steuern.
                      Wenn Sie Cookies ablehnen, können Sie die Plattform immer noch nutzen, aber Ihre Fähigkeit, einige Funktionen oder Bereiche der Plattform zu nutzen, kann eingeschränkt sein.
                    </p><br />
                    <p>
                      <span><strong>3. Google Analytics</strong></span> <br /><br />
                      Wir verwenden Google Analytics, um Informationen über Ihre Nutzung der Plattform zu sammeln.
                      Google Analytics ist ein Webanalysedienst von Google Inc. ("Google"). Google Analytics verwendet Cookies, um uns dabei zu helfen, zu analysieren, wie Nutzer die Plattform nutzen.
                      Die durch das Cookie erzeugten Informationen über Ihre Nutzung der Plattform (einschließlich Ihrer IP-Adresse) werden an Google übertragen und auf Servern in den USA gespeichert.<br />
                      Wir verwenden Google Analytics, um:<br />
                      - die Funktionalität der Plattform zu analysieren und zu verbessern; <br />
                      - zielgerichtete Werbung zu schalten..<br />
                      Sie können die Verwendung von Google Analytics durch Installation des Google Analytics Opt-out Browser Add-on, aufrufbar unter: <em><a target="_blank" href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on Download Page</a></em> abmelden.
                    </p><br />
                    <p>
                      <span><strong>4. Weitergabe von personenbezogener Informationen</strong></span> <br /><br />
                      Wir können Ihre persönlichen Daten unter den folgenden Umständen an Dritte weitergeben:<br />
                      - Dienstanbieter: Wir können Ihre personenbezogenen Daten an Drittanbieter weitergeben, die uns beim Betrieb der Plattform unterstützen, wie z. B. Zahlungsabwickler, Hosting-Anbieter und Kundendienstanbieter. <br />
                      - Geschäftspartner: Wir können Ihre personenbezogenen Daten an Geschäftspartner weitergeben, z. B. an Veranstalter, um den Kauf von Tickets zu erleichtern.<br />
                      - Gesetzliche Anforderungen: Wir können Ihre persönlichen Daten offenlegen, wenn wir gesetzlich dazu verpflichtet sind oder wenn wir der Meinung sind, dass eine solche Offenlegung notwendig ist, um rechtlichen Verfahren nachzukommen oder um unsere Rechte, unser Eigentum oder unsere Sicherheit zu schützen.<br />
                    </p><br />
                    <p>
                      <span><strong>5. Sicherheit der personenbezogenen Informationen</strong></span> <br /><br />
                      Wir ergreifen angemessene Maßnahmen, um Ihre persönlichen Daten vor unbefugtem Zugriff, unbefugter Nutzung oder Offenlegung zu schützen.
                      Es kann jedoch nicht garantiert werden, dass die Datenübertragung über das Internet vollkommen sicher ist, und wir können die Sicherheit Ihrer persönlichen Daten nicht garantieren.
                    </p><br />
                    <p>
                      <span><strong>6. Aufbewahrung personenbezogener Informationen</strong></span> <br /><br />
                      Wir werden Ihre personenbezogenen Daten so lange aufbewahren, wie es für die Erfüllung der Zwecke, für die sie erhoben wurden, erforderlich ist, es sei denn, eine längere Aufbewahrungsfrist ist gesetzlich vorgeschrieben.
                    </p><br />
                    <p>
                      <span><strong>7. Übermittlung von personenbezogener Information ins Ausland</strong></span> <br /><br />
                      Wir dürfen Ihre personenbezogenen Informationen nur dann ins Ausland übermitteln, wenn dies zur Erfüllung unserer vertraglichen und gesetzlichen Verpflichtungen erforderlich ist.
                      Dies kann der Fall sein, wenn Sie ein Ticket für eine Veranstaltung im Ausland buchen.
                      Bitte beachten Sie, dass Länder außerhalb der Europäischen Union möglicherweise Datenschutzrichtlinien haben, die nicht mit der Datenschutz-Grundverordnung übereinstimmen.
                      Dennoch werden personenbezogene Daten nur an Partner weitergegeben, die ausreichende Garantien für die Einhaltung des Schutzes Ihrer personenbezogenen Daten bieten.
                    </p><br />
                    <p>
                      <span><strong>8. Ihre Rechte</strong></span> <br /><br />
                      Sie haben das Recht, auf Ihre von uns erfassten personenbezogenen Daten zuzugreifen, sie zu korrigieren oder zu löschen.
                      Sie haben auch das Recht auf Einschränkung, Auskunft und Widerspruch Ihrer personenbezogenen Daten.<br />
                      Sie sind herzlich eingeladen, uns zu kontaktieren.<br /><br />
                      <strong>Vienna Event Tickets</strong><br />
                      Favoritenstraße 88-90, 1100 Vienna<br />
                      Tel: <a href="tel:+43 6767984066">+43 6767984066</a><br />
                      Email: <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a><br /><br />
                      Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in irgendeiner Weise verletzt wurden, nehmen Sie bitte Kontakt mit uns auf. Ihre Anfrage wird so schnell wie möglich bearbeitet.
                      Wir werden uns spätestens innerhalb von 30 Tagen mit Ihnen in Verbindung setzen. Darüber hinaus haben Sie das Recht, sich an die Datenschutzbehörde zu wenden.
                    </p><br />
                    <p>
                      <span><strong>9. Urheberrecht</strong></span> <br /><br />
                      Der Inhalt der Plattform ist urheberrechtlich geschützt. Die bereitgestellten Informationen sind nur für den persönlichen Gebrauch bestimmt.
                      Jede weitergehende Nutzung, wie z.B. Vervielfältigung oder jede Form der kommerziellen Nutzung ist ohne unsere Zustimmung untersagt.
                    </p><br />
                    <p>
                      <span><strong>10. Haftungsausschluss</strong></span> <br /><br />
                      Im Hinblick auf die technischen Eigenschaften der Plattform kann keine Gewähr für die Authentizität, Richtigkeit und Vollständigkeit der auf der Plattform zur Verfügung gestellten Informationen übernommen werden.
                      Es wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der gegenständlichen Website und ihrer Inhalte übernommen.
                      Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, die aus der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Homepage erwachsen, wird, soweit rechtlich zulässig, ausgeschlossen. Wir weisen darauf hin, dass für den Inhalt der Hyperlinks keine Verantwortung und keine Haftung übernommen wird.
                      Diese anderen Plattformen, zu denen sich direkte Zugangsmöglichkeiten (Links) auf den Firmen-Websites befinden, werden nicht hinsichtlich Inhalt und Gesetzmäßigkeit überprüft.
                      Wir übernehmen keinen Einfluss auf die Gestaltung dieser Websiten und distanziert sich ausdrücklich von den dort dargestellten, möglicherweise rechtswidrigen Inhalten.
                      Darüber hinaus übernehmen wir keine Verantwortung für solche Inhalte und haften für derartige Inhalte nicht. Eine Einbindung einzelner Seiten oder sonstiger Auszüge von <a href="https://viennaeventtickets.com">www.viennaeventtickets.com</a> in fremde Frames ist untersagt.
                    </p><br />
                    <p>
                      <span><strong>11. Gender-Angaben</strong></span> <br /><br />
                      Alle geschlechtsunspezifischen Angaben auf unserer Website folgen dem Unisex-Prinzip. Die geschlechtsunspezifischen Angaben gelten daher für alle Geschlechter gleichermaßen.
                    </p><br />
                  </div>
                ) : (
                  <div className="content px-6">
                    <p>This Privacy Policy applies to the online ticket platform <a href="https://viennaeventtickets.com">www.viennaeventtickets.com</a> owned and operated by Vienna Event Tickets, based in Vienna, Austria ("we" or "us").
                      We are committed to protecting the privacy and security of personal information that we collect from users of the Platform ("you" or "users").</p>
                    <br />
                    <p>
                      <span><strong>1. Collection and Use of Personal Information</strong></span><br /><br />
                      We may collect the following types of personal information from users of the Platform:
                      - Contact Information: This includes your name, title, address, phone number, and mailing address.
                      We collect this information when you create an account on the platform or when you purchase a ticket.<br />
                      - Payment Information: We may collect payment information, including your credit card or other payment information, when you purchase a ticket through the platform.<br />
                      - Usage Information: We may collect information about your use of the platform, such as your browsing history, search history, and IP address. <br />
                      We use this personal information to:
                      - Process and fulfill your ticket orders;<br />
                      - Respond to your inquiries and requests or cancellations; <br />
                      - Provide customer service; <br />
                      - Analyze and improve the platform's functionality; <br />
                      - Prevent fraud and other illegal activities; <br />
                      - Comply with legal and regulatory requirements.<br />
                      Please note that by entering your personal information on the platform, you consent to the usage of the data for the reasons mentioned above.
                      The data entered is kept safe and will not be shared with third parties, unless legally obliged.
                    </p><br />
                    <p>
                      <span><strong>2. Cookies</strong></span> <br /><br />
                      We may use cookies and other tracking technologies to collect information about your use of the platform.
                      Cookies are small data files that are stored on your device when you visit a website.
                      Cookies provide users with additional features, such as, remembering your preferences, the ability to continue using the website where you left it.
                      Cookies cannot read, access, or modify any data on your computer.
                      We use cookies to:<br />
                      - Remember your preferences and settings; <br />
                      - Personalize your experience on the platform;<br />
                      - Analyze and improve the platform's functionality;<br />
                      - Serve targeted advertising.<br />
                      You can control the use of cookies at the individual browser level.
                      If you reject cookies, you may still use the platform, but your ability to use some features or areas of the platform may be limited.
                    </p><br />
                    <p>
                      <span><strong>3. Google Analytics</strong></span> <br /><br />
                      We use Google Analytics to collect information about your use of the platform.
                      Google Analytics is a web analytics service provided by Google, Inc. ("Google").
                      Google Analytics uses cookies to help us analyze how users use the platform.
                      The information generated by the cookie about your use of the platform (including your IP address) will be transmitted to and stored by Google on servers in the United States.
                      If legally required, Google will transfer the collected information to third parties.<br />
                      We use Google Analytics to:<br />
                      - Analyze and improve the Platform's functionality; <br />
                      - Serve targeted advertising.<br />
                      You can opt-out of Google Analytics by installing the Google Analytics Opt-out Browser Add-on, available at <em><a target="_blank" href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on Download Page</a></em>
                    </p><br />
                    <p>
                      <span><strong>4. Disclosure of Personal Information</strong></span> <br /><br />
                      We may disclose your personal information to third parties in the following circumstances:<br />
                      - Service Providers: We may share your personal information with third-party service providers that help us operate the platform, such as payment processors, hosting providers, and customer service providers. <br />
                      - Business Partners: We may share your personal information with business partners, such as event organizers, in order to facilitate the purchase of tickets.<br />
                      - Legal Requirements: We may disclose your personal information if required to do so by law or if we believe that such disclosure is necessary to comply with legal processes or to protect our rights, property, or safety.<br />
                    </p><br />
                    <p>
                      <span><strong>5. Security of Personal Information</strong></span> <br /><br />
                      We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.
                      However, no data transmission over the Internet can be guaranteed to be completely secure, and we cannot guarantee the security of your personal information.
                    </p><br />
                    <p>
                      <span><strong>6. Retention of Personal Information</strong></span> <br /><br />
                      We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected unless a longer retention period is required by law.
                    </p><br />
                    <p>
                      <span><strong>7. Transmission of Personal Information abroad</strong></span> <br /><br />
                      We may transfer your personal data abroad only if required to fulfill our contractual and legal obligations.
                      This might be the case when booking a ticket for an event abroad. Please note that countries outside the European Union may have privacy policies that do not compile with the GDPR.
                      Still, personal information will only be transferred to partners who provide sufficient guarantees of compliance with the protection of your personal information.
                    </p><br />
                    <p>
                      <span><strong>8. Your Rights</strong></span> <br /><br />
                      You have the right to access, correct, or delete your personal information that we have collected.
                      You also have the right to restriction, information, and objection of your personal data.<br />
                      You are welcome to contact us.<br /><br />
                      <strong>Vienna Event Tickets</strong><br />
                      Favoritenstraße 88-90, 1100 Vienna<br />
                      Tel: <a href="tel:+43 6767984066">+43 6767984066</a><br />
                      Email: <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a><br /><br />
                      If you believe that the processing of your data violates data protection law or if your data protection claims have otherwise been violated in any way, please contact us.
                      You request will be processed as soon as possible. We will contact you within 30 days at the latest. In addition, you have the right to contact the Data Protection Authority.
                    </p><br />
                    <p>
                      <span><strong>9. Copyright</strong></span> <br /><br />
                      The content of the platform is protected by copyright. The information provided is only for personal use. Any further use, such as, duplication or any form of commercial use is without our permission forbidden.
                    </p><br />
                    <p>
                      <span><strong>10. Disclaimer</strong></span> <br /><br />
                      With regard to the technical characteristics of the platfom, no guarantee can be given for the authenticity, accuracy and completeness of the information made available on the platform.
                      Furthermore, no guarantee is given for the availability or operation of the platform and its contents.
                      Any liability for direct, indirect or other damages, regardless of their causes, which arise from the use or unavailability of the data and information on the platdorm, is excluded, as far as legally permissible.
                      Please note that for the contents of the hyperlinks we take no responsibility and no liability over. We do not review other websites that have direct accessibility (links) on the company websites, in terms of content and legality.
                      We assume no influence on the design of these websites and expressly dissociates ourselves from the possibly unlawful content displayed there. Furthermore, we do not assume any responsibility for such content and are not liable for such content.
                      The integration of pages or any other excerpts from <a href="https://viennaeventtickets.com">www.viennaeventtickets.com</a> in foreign frames is prohibited.
                    </p><br />
                    <p>
                      <span><strong>11. Gender statement</strong></span> <br /><br />
                      All nonspecific gender information on our platform follows the unisex principle. Hence, they apply equally to all genders.
                    </p><br />
                  </div>
                )}

              </div>
            </div>
          </>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyAndPolicy;
export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.footer_privacy_policy}
    pathname={location.pathname}
  />
);